import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../ContextProvider/ContextProvider";
import Icon from "../../general/Icon";
import Image from "../../general/Image";
import Modal from "../../general/Modal";
import { get, IMAGE_API_URL, post } from "../../utils/apiRequest";
import { primaryColorLight } from "../../utils/Theme";
import Table from "../Table/Table";
import "./Css/Orders.css";
import UpdateOrder from "./UpdateOrder";
import ViewOrder from "./ViewOrder";
import { PAGINATION_LIMIT } from "../../utils/Constants";
import Pagination from "../Pagination/Pagination";

export default function Orders() {
  const ordersData = useContext(GlobalContext).ordersData;
  const updateOrdersData = useContext(GlobalContext).setOrdersData;

  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");

  const [showViewModal, setShowViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({});

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateModalData, setUpdateModalData] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrders();
  }, []);

  async function getOrders() {
    console.log("HITTING API");
    const response = await get("order");
    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.status === "success") updateOrdersData(responseJson.data);
  }

  const totalTableData = [];
  if (ordersData.length) {
    ordersData.forEach((i) =>
      totalTableData.push({
        _id: i._id,
        name: i.userMeta.firstName + " " + i.userMeta.lastName,
        email: i.userMeta.email,
        status: i.status,

        totalAmount:
          i.currency === "INR" ? i.totalAmount + " ₹" : i.totalAmount + " $",
        created_at: new Date(i.createdAt).toLocaleDateString("en-IN"),
        order_type: i.orderType.replace("_", " "),
        // view_image: (
        //   <div style={{ display: "flex" }} onClick={() => fetchMapImage(i._id)}>
        //     <Icon image="view.svg" size={20} />{" "}
        //     <span style={{ paddingLeft: 10 }}>View Image</span>
        //   </div>
        // ),
        Date:
          new Date(i.createdAt).toLocaleDateString("en-In") +
          " " +
          new Date(i.createdAt).toLocaleTimeString("en-IN"),//date and time
      })
    );
  }
  const totalPages = Math.ceil(totalTableData.length / PAGINATION_LIMIT);
  const startIndex = (activePage - 1) * PAGINATION_LIMIT;
  const endIndex = startIndex + PAGINATION_LIMIT;
  let paginatedTable = [...totalTableData].slice(startIndex, endIndex);

  const gotoPrevious = async () => {
    try {
      setActivePage(activePage - 1);
      window.scrollTo(0, 0);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const gotoNext = async () => {
    try {
      setActivePage(activePage + 1);
      window.scrollTo(0, 0);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const jumpToPage = async (pageNumber) => {
    try {
      setActivePage(pageNumber);
      window.scrollTo(0, 0);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  async function fetchMapImage(id) {
    const selectedOrder = ordersData.find((i) => i._id === id);
    console.log(selectedOrder);
    const payload = selectedOrder.designInfo;
    console.log(payload);

    try {
      const response = await post(
        "cosmicframe_renderer",
        payload,
        IMAGE_API_URL
      );
      console.log(response);
      if (response.status === 200) {
        const responseBlob = await response.blob();
        setImage(URL.createObjectURL(responseBlob));
        console.log(responseBlob);
        setShowImageModal(true);
      } else {
        const responseJson = await response.json();
        throw new Error(responseJson);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function viewOrder(orderId) {
    console.log(orderId);
    setShowViewModal(true);

    const selectedOrder = ordersData.find((i) => i._id === orderId);
    setViewModalData(selectedOrder);
    console.log(selectedOrder);
  }

  async function editOrder(orderId) {
    console.log(orderId);
    setShowUpdateModal(true);
    const selectedOrder = ordersData.find((i) => i._id === orderId);
    setUpdateModalData(selectedOrder);
    console.log(selectedOrder);
  }

  return (
    <>
      <div className="orders-base">
        <h2>Orders</h2>
        <Pagination
          total={totalPages}
          currentCount={activePage}
          gotoNext={() => gotoNext()}
          gotoPrevious={gotoPrevious}
          loading={loading}
          jumpToPage={(value) => jumpToPage(value)}
        >
          <Table
            keys={["name", "order_type", "Date", "totalAmount", "status"]}
            data={paginatedTable}
            actionKey={"_id"}
            viewAction={(value) => viewOrder(value)}
            editAction={(value) => editOrder(value)}
          />
        </Pagination>
      </div>

      <Modal show={showImageModal} close={() => setShowImageModal(false)}>
        <div
          style={{
            background: primaryColorLight,
            overflowY: "auto",
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            width: 392,
          }}
        >
          <Image src={image} paddingBottom={"167%"} />
        </div>
      </Modal>

      <Modal show={showViewModal} close={() => setShowViewModal(false)}>
        <ViewOrder data={viewModalData} />
      </Modal>

      <Modal show={showUpdateModal} close={() => setShowUpdateModal(false)}>
        <UpdateOrder
          data={updateModalData}
          getOrders={() => getOrders()}
          closeModal={() => setShowUpdateModal(false)}
          totalDataStore={ordersData}
          updateTotalOrderStore={updateOrdersData}
        />
      </Modal>
    </>
  );
}
