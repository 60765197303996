import React from "react";
import Icon from "../../general/Icon";
import "./Css/Table.css";

export default function Table(props) {
  function handleClick(e) {
    if (props.onClick) {
      props.onClick(e);
    }
  }

  return (
    <table className="table-base">
      <thead>
        <tr>
          {props.keys.map((i, index) => (
            <th key={index} className="table-head">
              {i.replace("_", " ")}
            </th>
          ))}

          {(props.viewAction || props.editAction || props.deleteAction) && (
            <th>Actions</th>
          )}
        </tr>
      </thead>

      <tbody>
        {props.data.map((i, index) => (
          <tr
            key={index}
            className="table-row"
            onClick={() => props.onClick && props.onClick(i[props.actionKey])}
          >
            {props.keys.map((objKey, index2) => (
              <td className="table-cell" key={index2}>
                {i[objKey]}
              </td>
            ))}

            {(props.viewAction || props.editAction || props.deleteAction) && (
              <td className="table-cell">
                <div className="coupon-tableAction ">
                  {props.viewAction && (
                    <span onClick={() => props.viewAction(i[props.actionKey])}>
                      <Icon image="view.svg" size={18} />
                    </span>
                  )}

                  {props.editAction && (
                    <span onClick={() => props.editAction(i[props.actionKey])}>
                      <Icon image="edit.svg" size={18} />
                    </span>
                  )}

                  {props.deleteAction && (
                    <span
                      onClick={() => props.deleteAction(i[props.actionKey])}
                    >
                      <Icon image="delete.svg" size={18} />
                    </span>
                  )}
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

Table.defaultProps = {};
