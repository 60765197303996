import React, { useState } from 'react';
import './Css/Login.css';
import { buttonFont, buttonRadius, primaryColor } from '../../utils/Theme';
import Button from '../../general/Button';
import { post } from '../../utils/apiRequest';
import { useNavigate, Navigate } from 'react-router-dom';
import { createCookieInSeconds, getCookie } from '../../utils/Cookies';
import { ACCESS_TOKEN, USER_LOGIN_EXPIRY_TIME } from '../../utils/Constants';
import { DASHBOARD_PATH } from '../../utils/RouteUrl';

export default function Login(props) {
  const navigate = useNavigate();
  const userLoggedIn = getCookie(ACCESS_TOKEN);
  const [input, setInput] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  if (userLoggedIn) return <Navigate replace to={DASHBOARD_PATH} />;

  const onLogin = async () => {
    try {
      setLoading(true);
      const response = await post('login', input);
      console.log(response);
      const resultJson = await response.json();
      console.log(resultJson);
      if (resultJson.status === 'success') {
        const token = await resultJson.data.token;
        createCookieInSeconds(ACCESS_TOKEN, token, USER_LOGIN_EXPIRY_TIME);
        navigate(DASHBOARD_PATH);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className='login-base'>
      <div className='login-box'>
        <h3 className='login-header'>Sign in your account</h3>

        <div className={'login-formGroup'}>
          <label className='label'>Email</label>
          <input
            className='inputBox'
            type='email'
            background='white'
            value={input.email}
            onChange={(e) => setInput({ ...input, email: e.target.value })}
          />
        </div>

        <div className={'login-formGroup'}>
          <label className='label'>Password</label>
          <input
            className='inputBox'
            type='password'
            background='white'
            value={input.password}
            onChange={(e) => setInput({ ...input, password: e.target.value })}
          />
        </div>

        {/* <div className={styles.formHalfGroup}>
          <div className={styles.checboxGroup}>
            <div className={styles.checbox}>
              <Input
                type="checkbox"
                backgroundDiv="transparent"
                height="16px"
                cursor="pointer"
              />
            </div>
            <p>Remember my preference</p>
          </div>
          <p
            className={styles.forgotPasswordLabel}
            onClick={() => {
              props.history.push(FORGOT_PASSWORD_PATH);
            }}
          >
            Forgot Password?
          </p>
        </div> */}

        <div className='login-submitButton'>
          <Button
            label='Sign me in'
            backgroundColor={primaryColor}
            borderRadius={buttonRadius}
            // border="none"
            textStyle={{ fontSize: buttonFont }}
            onClick={onLogin}
            loading={loading}
          />
        </div>

        {/* <nav
          style={{
            borderBottom: "solid 1px",
            paddingBottom: "1rem",
          }}
        >
          <Link to="/invoices">Invoices</Link> |{" "}
          <Link to="/expenses">Expenses</Link>
        </nav> */}

        {/* <div className={styles.authPageSwitch}>
          <p>
            Don't have an account?
            <span
              onClick={() => {
                props.history.push(REGISTER_PATH);
              }}
            >
              {" "}
              Sign up{" "}
            </span>
          </p>
        </div> */}
      </div>
    </div>
  );
}
