import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../general/Icon";
import { ACCESS_TOKEN } from "../../utils/Constants";
import { deleteCookie } from "../../utils/Cookies";
import { LOGIN_PATH } from "../../utils/RouteUrl";
import "./Css/Header.css";

function Header(props) {
  const navigate = useNavigate();
  return (
    <header className="header-base">
      <div className="header-logo">CosmicFrame</div>

      <div
        className="header-profileIcon"
        onClick={() => {
          deleteCookie(ACCESS_TOKEN);
          navigate(LOGIN_PATH);
        }}
      >
        <Icon image={"logout.svg"} size={15} />
      </div>
    </header>
  );
}

export default Header;
