import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../ContextProvider/ContextProvider";
import Button from "../../general/Button";
import { patch } from "../../utils/apiRequest";
import { ORDER_STATUS } from "../../utils/Constants";
import "./Css/UpdateOrder.css";

export default function UpdateOrder(props) {
  const [status, setStatus] = useState(props.data.status);
  const setShowToast = useContext(GlobalContext).setShowToast;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.data) setStatus(props.data.status);
  }, [props.data]);

  const updateStatus = async () => {
    try {
      const orderId = props.data._id;
      const payload = {
        status,
      };
      console.log(orderId);
      console.log(payload);

      const response = await patch(
        `order/updateOrderStatus/${orderId}`,
        payload
      );
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.status === "success") {
        const data = [...props.totalDataStore];
        console.log(props.totalDataStore);
        const filterData = data.filter((i) => {
          return i._id !== responseJson.data._id;
        });
        filterData.push(responseJson.data);
        props.updateTotalOrderStore(filterData);

        setShowToast({
          status: true,
          message: {
            head: "Success",
            content: "Status Updated",
          },
        });
      }

      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="editOrder-base">
      <h2 className="main-header">Update Order Status</h2>

      <div className={"login-formGroup"}>
        <label className="label">Change Status</label>

        <div>
          <select
            className="updateorder-select"
            defaultValue={status ? status : "DEFAULT"}
            onChange={(e) => setStatus(e.target.value)
            
            }
          >
            <option className="optionGroup" value="DEFAULT" disabled>
              Select Status
            </option>
            {Object.values(ORDER_STATUS).map((i, index) => (
              <option value={i} key={index}>
                {i}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Button
        label={"Update Status"}
        borderRadius={5}
        loading={loading}
        onClick={() => status !== props.data.status && updateStatus()}
      />
    </div>
  );
}
