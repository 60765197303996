import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../ContextProvider/ContextProvider";
import Modal from "../../general/Modal";
import { get, IMAGE_API_URL, post } from "../../utils/apiRequest";
import Table from "../Table/Table";
import "../Orders/Css/Orders.css";

import UpdatePayment from "./UpdatePayment";

export default function Payments() {
  const paymentsData = useContext(GlobalContext).payments;
  const updatePaymentsData = useContext(GlobalContext).setPayments;



  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateModalData, setUpdateModalData] = useState({});

  useEffect(() => {
    getOrders();
  }, []);

  async function getOrders() {
    const response = await get("payment");
    const responseJson = await response.json();
    if (responseJson.status === "success") updatePaymentsData(responseJson.data);
  }

  const totalTableData = [];
  if (paymentsData.length) {
    paymentsData.forEach((i) =>
      totalTableData.push({
        _id: i._id,
        order_type: i.type.replace("_", " "),
       amount_inr : i.price && i.price.inr,
       amount_usd : i.price && i.price.usd,
       frame_size : i.frameSize
      })
    );
  }
  let paginatedTable = totalTableData

  async function editOrder(orderId) {
    setShowUpdateModal(true);
    const selectedOrder = paymentsData.find((i) => i._id === orderId);
    setUpdateModalData(selectedOrder);
  }
  return (
    <>
      <div className="orders-base">
        <h2>Payments</h2>
       
          <Table
            keys={["order_type", "amount_inr","amount_usd", "frame_size"]}
            data={paginatedTable}
            actionKey={"_id"}
            editAction={(value) => editOrder(value)}
          />
      </div>

      <Modal show={showUpdateModal} close={() => setShowUpdateModal(false)}>
        <UpdatePayment
          data={updateModalData}
          getOrders={() => getOrders()}
          closeModal={() => setShowUpdateModal(false)}
          totalDataStore={paymentsData}
          updateTotalOrderStore={updatePaymentsData}
        />
      </Modal>
    </>
  );
}
