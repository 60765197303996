import React, { useEffect, useState } from "react";
import HorizontalTable from "../../general/HorizontalTable";
import { IMAGE_API_URL, post } from "../../utils/apiRequest";
import "../Coupons/Css/ViewCoupon.css";

export default function ViewOrder(props) {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (!Object.keys(props.data).length) return;
    fetchMapImage();
  }, [props.data]);

  if (!Object.keys(props.data).length) return null;

  const fetchMapImage = async () => {
    const payload = props.data.designInfo;
    try {
      const response = await post(
        "cosmicframe_renderer",
        payload,
        IMAGE_API_URL
      );
      if (response.status === 200) {
        const responseBlob = await response.blob();
        setImage(URL.createObjectURL(responseBlob));
      } else {
        const responseJson = await response.json();
        throw new Error(responseJson);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderFormUI = links => {
    return (
      <div>
        {links.highRes && (
          <span
            className={"download-icon-link"}
            style={{ color: "#36df19" }}
            title="High Resolution"
          >
            <a href={links.highRes} __blank>
              HI
            </a>
          </span>
        )}
        {links.mediumRes && (
          <span
            className={"download-icon-link"}
            style={{ color: "#f17e03" }}
            title="Avg Resolution"
          >
            <a href={links.mediumRes} __blank>
              AVG
            </a>
          </span>
        )}
        {links.lowRes && (
          <span
            className={"download-icon-link"}
            style={{ color: "#df0a0a" }}
            title="Low Resolution"
          >
            <a href={links.lowRes} __blank>
              LOW
            </a>
          </span>
        )}
      </div>
    );
  };
  const data = {
    _id: props.data._id,
    address:
      props.data.address.addressLine1 +
      ", " +
      props.data.address.addressLine2 +
      ", " +
      props.data.address.addressLine2 +
      ", " +
      props.data.address.city +
      ", " +
      props.data.address.state +
      ", " +
      props.data.address.country +
      ", " +
      props.data.address.zipCode,
    currency: props.data.currency,
    discount:
      props.data.discount + `${props.data.currency === "INR" ? " ₹" : " $"}`,
    totalAmount:
      props.data.totalAmount + `${props.data.currency === "INR" ? " ₹" : " $"}`,
    subTotal:
      props.data.subTotal + `${props.data.currency === "INR" ? " ₹" : " $"}`,
    orderType: props.data.orderType,
    status: props.data.status,
    userId: props.data.userId,
    name: props.data.userMeta.firstName + " " + props.data.userMeta.lastName,
    email: props.data.userMeta.email,
    phoneNumber: props.data.userMeta.phoneNumber,
    createdAt: new Date(props.data.createdAt).toLocaleDateString("en-IN"),
    updatedAt: new Date(props.data.updatedAt).toLocaleDateString("en-IN")
  };

  const designInfo = JSON.parse(JSON.stringify(props.data.designInfo));
  delete designInfo.text;
  designInfo.location_text = props.data.designInfo.text.location;
  designInfo.time_text = props.data.designInfo.text.time;
  designInfo.coords_text = props.data.designInfo.text.coords;
  designInfo.heading_1 =
    props.data.designInfo.text.heading && props.data.designInfo.text.heading;
  designInfo.heading_2 =
    props.data.designInfo.text.heading_2 &&
    props.data.designInfo.text.heading_2;
  designInfo.sub_heading = props.data.designInfo.text.sub_heading;
  designInfo.size = props.data.size;
  if (props.data && props.data.s3Links) {
    designInfo.available_format = {
      component: renderFormUI(props.data.s3Links)
    };
  }

  return (
    <div className="viewCoupon-base">
      <h2 className="viewCoupon-header">View Order</h2>
      <HorizontalTable keys={Object.keys(data)} data={data} />

      <h3 className="viewCoupon-subHeader">Design Information</h3>
      <HorizontalTable keys={Object.keys(designInfo)} data={designInfo} />

      {/* <div style={{ marginTop: 25 }}>
        <img src={image} width="70%" />
      </div> */}
    </div>
  );
}
