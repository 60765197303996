export const PUBLIC_IMAGE_PATH = '/assets/images/';
export const ACCESS_TOKEN = 'admin_access_token';
export const USER_LOGIN_EXPIRY_TIME = 60 * 60 * 24 * 10; // 10 Days

export const COUPON_STATUS = { active: 'active', inActive: 'in_active' };

export const PAGINATION_LIMIT = 10;

export const ORDER_STATUS = {
  pending: 'pending',
  // paid: "paid",
  processed: 'processed',
  shipped: 'shipped',
  delivered: 'delivered',
  failed: 'failed',
  cancelled: 'cancelled'
};

export const PERCENT = 'percentage';
