import React from "react";
import PropTypes from "prop-types";
import "./Icon.css";
import VisibilityChild from "./VisibilityChild";
import { PUBLIC_IMAGE_PATH } from "../utils/Constants";

export default class Icon extends React.Component {
  // shouldComponentUpdate(nextProps) {
  //   if (this.props.image === nextProps.image) {
  //     return false;
  //   }
  // }
  render() {
    return (
      <VisibilityChild>
        <div
          className={"icon-base"}
          style={{ width: this.props.size, height: this.props.size }}
        >
          <div
            className={"icon-image"}
            style={{
              backgroundImage: `url("${PUBLIC_IMAGE_PATH}${this.props.image}")`,
              backgroundSize: `${this.props.backgroundSize}`,
            }}
          />
        </div>
      </VisibilityChild>
    );
  }
}

Icon.propTypes = {
  size: PropTypes.number.isRequired,
  backgroundSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  size: 30,
  backgroundSize: "contain",
};
