import "./Modal.css";

const Modal = ({ show, close, children, position, style, transform }) => {
  const positionStyle = { justifyContent: "center", alignItems: "center" };
  if (position === "top") positionStyle.alignItems = "start";
  if (position === "bottom") positionStyle.alignItems = "end";
  if (position === "left") positionStyle.justifyContent = "left";
  if (position === "right") positionStyle.justifyContent = "right";

  return (
    <>
      <div
        className={`modalContainer ${show ? "show" : ""} `}
        onClick={() => close()}
        style={positionStyle}
      >
        <div
          className="modal"
          onClick={(e) => e.stopPropagation()}
          style={{ ...style, transform: !show && transform }}
        >
          <main className="modal_content">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Modal;
