import React from "react";
import "./Css/Sidebar.css";
import { NavLink } from "react-router-dom";
import {
  COUPONS_PATH,
  DASHBOARD_PATH,
  ORDERS_PATH,
  PAYMENTS_PATH,
  
} from "../../utils/RouteUrl";
import Icon from "../../general/Icon";

const SIDEBAR_LINKS = [
  {
    icon: "dashboard.svg",
    label: "Dashboard",
    url: DASHBOARD_PATH,
  },
  {
    icon: "coupon.svg",
    label: "Coupon",
    url: COUPONS_PATH,
  },
  {
    icon: "order.svg",
    label: "Orders",
    url: ORDERS_PATH,
  },
  {
    icon: "order.svg",
    label: "Payments",
    url: PAYMENTS_PATH,
  },
];

export default function Sidebar(props) {
  return (
    <section className="sidebar-base">
      <div className="sidebar-logoContainer">
        <h1>Cosmicframe</h1>
      </div>
      <nav className="sidebar-navLinksContainer">
        {SIDEBAR_LINKS.map((i, index) => (
          <NavLink
            to={i.url}
            className={({ isActive }) =>
              isActive ? "sidebar-navLink-active" : "sidebar-navLink"
            }
            key={index}
          >
            <div className="sidebar-navLink-icon">
              <Icon image={i.icon} size={20} />
            </div>
            <h4 className="sidebar-navLink-label">{i.label}</h4>
          </NavLink>
        ))}
      </nav>
    </section>
  );
}
