import React from "react";
import "./HorizontalTable.css";

export default function HorizontalTable(props) {
  const getValueString = (objKey) => {
    let valueString;
    if (props.data[objKey] === undefined || props.data[objKey] === null) valueString = "N/E"
    else if (props.data[objKey] && props.data[objKey].component){
      return props.data[objKey].component
    }
    else valueString = props.data[objKey].toString()
    return valueString;
  }
  return (
    <table>
      <tbody>
        {/* Object.keys(data).map(i=> console.log(i,": ",data[i])) */}
        {props.keys.map((objKey, index) => (
          <tr key={index}>
            <td className="horizontalTable-headingCol">{objKey.replace("_", " ")}</td>
            <td className="horizontalTable-valueCol">{getValueString(objKey)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

HorizontalTable.defaultProps = {
  data: [],
};
