import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GlobalContext } from "../ContextProvider/ContextProvider";
import Toast from "../general/Toast";
import { ACCESS_TOKEN } from "../utils/Constants";
import { getCookie } from "../utils/Cookies";
import { LOGIN_PATH } from "../utils/RouteUrl";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";

const SIDEBAR_STYLES = {
  position: "fixed",
  width: "250px",
  top: 0,
  left: 0,
  zIndex: 100,
};

const HEADER_STYLES = {
  position: "fixed",
  width: "100%",
  height: "50px",
  marginLeft: SIDEBAR_STYLES.width,
  zIndex: 100,
  calc : 100% - SIDEBAR_STYLES.width,
};

export default function WithSidebarHeaderWrapper(props) {
  const showToast = useContext(GlobalContext).showToast;
  const setShowToast = useContext(GlobalContext).setShowToast;

  const userLoggedIn = getCookie(ACCESS_TOKEN);
  if (!userLoggedIn) return <Navigate replace to={LOGIN_PATH} />;

  return (
    <>
      {showToast.status && (
        <Toast
          heading={showToast.message.head}
          content={showToast.message.content}
          resetToast={() => setShowToast(false)}
        />
      )}
      <div className={"sidebarHeaderWrapper-base"} style={{ display: "flex" }}>
        <section
          className={"sidebarHeaderWrapper-sidebarContainer"}
          style={SIDEBAR_STYLES}
        >
          <Sidebar />
        </section>

        <section style={HEADER_STYLES}>
          <Header />
        </section>

        <section
          className={"backgroundWrapper"}
          style={{
            flex: 1,
            marginLeft: SIDEBAR_STYLES.width,
            marginTop: HEADER_STYLES.height,
            minHeight: `calc(100vh - ${HEADER_STYLES.height})`,
            background: "#f8f8f8",
            padding: 30,
          }}
        >
          <div
            className={"contentWrapper"}
            style={{
              width: "100%",
              padding: 50,
              borderRadius: 20,
              boxShadow: `0 0 35px 0 rgb(154 161 171 / 15%)`,
              background: "#fff",
            }}
          >
            <Outlet />
          </div>
        </section>
      </div>
    </>
  );
}
