import { createContext, useState } from "react";

export const GlobalContext = createContext();

export default function ContextProvider(props) {
  const [showToast, setShowToast] = useState({
    status: false,
    message: { head: "", content: "" },
  });

  const [orderCount, setOrderCount] = useState([]);
  const [paidOrders, setPaidOrders] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [payments, setPayments] = useState([]);

  return (
    <GlobalContext.Provider
      value={{
        showToast,
        setShowToast,
        orderCount,
        setOrderCount,
        paidOrders,
        setPaidOrders,
        couponData,
        setCouponData,
        ordersData,
        setOrdersData,
        payments,
        setPayments
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
