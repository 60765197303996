import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../ContextProvider/ContextProvider";
import Icon from "../../general/Icon";
import Image from "../../general/Image";
import Modal from "../../general/Modal";
import { get, IMAGE_API_URL, post } from "../../utils/apiRequest";
import { PUBLIC_IMAGE_PATH } from "../../utils/Constants";
import { primaryColorLight } from "../../utils/Theme";
import { PAGINATION_LIMIT } from "../../utils/Constants";
import UpdateOrder from "../Orders/UpdateOrder";
import ViewOrder from "../Orders/ViewOrder";
import Table from "../Table/Table";
import "./Css/Dashboard.css";
import Pagination from "../Pagination/Pagination";

export default function Dashboard() {
  const orderCount = useContext(GlobalContext).orderCount;
  const setOrderCount = useContext(GlobalContext).setOrderCount;
  const paidOrders = useContext(GlobalContext).paidOrders;
  const setPaidOrders = useContext(GlobalContext).setPaidOrders;

  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");

  const [showViewModal, setShowViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({});

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateModalData, setUpdateModalData] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderCountData();
    getPaidOrderData();
  }, []);

  const getOrderCountData = async () => {
    const response = await get("dashboard/orderCount");
    console.log(response);

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.status === "success") {
      setOrderCount(responseJson.data);
    }
  };

  const getPaidOrderData = async () => {
    const payload = {
      status: ["paid"],
    };
    const response = await post("getOrdersByStatus", payload);
    console.log(response);

    const responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.status === "success") {
      setPaidOrders(responseJson.data);
    }
  };

  // const fetchMapImage = async (id) => {
  //   setShowImageModal(true);
  //   const selectedOrder = paidOrders.find((i) => i._id === id);
  //   console.log(selectedOrder);
  //   const payload = selectedOrder.designInfo;
  //   console.log(payload);

  //   try {
  //     const response = await post(
  //       "cosmicframe_renderer",
  //       payload,
  //       IMAGE_API_URL
  //     );
  //     console.log(response);
  //     if (response.status === 200) {
  //       const responseBlob = await response.blob();
  //       setImage(URL.createObjectURL(responseBlob));
  //       console.log(responseBlob);
  //     } else {
  //       const responseJson = await response.json();
  //       setShowImageModal(false);
  //       throw new Error(responseJson);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const viewOrder = async (orderId) => {
    console.log(orderId);
    setShowViewModal(true);

    const selectedOrder = paidOrders.find((i) => i._id === orderId);
    setViewModalData(selectedOrder);
    console.log(selectedOrder);
  };

  const editOrder = async (orderId) => {
    console.log(orderId);
    setShowUpdateModal(true);
    const selectedOrder = paidOrders.find((i) => i._id === orderId);
    setUpdateModalData(selectedOrder);
    console.log(selectedOrder);
  };

  const totalTableData = [];

  if (paidOrders.length) {
    paidOrders.forEach((i) =>
      totalTableData.push({
        _id: i._id,
        name: i.userMeta.firstName + " " + i.userMeta.lastName,
        email: i.userMeta.email,
        status: i.status,
        totalAmount:
          i.currency === "INR" ? i.totalAmount + " ₹" : i.totalAmount + " $",
        created_at: new Date(i.createdAt).toLocaleDateString("en-IN"),
        order_type: i.orderType.replace("_", " "),
        // view_image: (
        //   <div style={{ display: "flex" }} onClick={() => fetchMapImage(i._id)}>
        //     <Icon image="view.svg" size={20} />{" "}
        //     <span style={{ paddingLeft: 10 }}>View Image</span>
        //   </div>
        // ),
        Date:
          new Date(i.createdAt).toLocaleDateString("en-IN") +
          " " +
          new Date(i.createdAt).toLocaleTimeString("en-IN"), // date and time 
      })
    );
  }

  console.log(paidOrders);
  const totalPages = Math.ceil(totalTableData.length / PAGINATION_LIMIT);
  const startIndex = (activePage - 1) * PAGINATION_LIMIT;
  console.log("START", startIndex);
  const endIndex = startIndex + PAGINATION_LIMIT;
  console.log("END", endIndex);
  let paginatedTable = [...totalTableData].slice(startIndex, endIndex);

  const gotoPrevious = async () => {
    try {
      setActivePage(activePage - 1);
      window.scrollTo(0, 0);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const gotoNext = async () => {
    try {
      setActivePage(activePage + 1);
      window.scrollTo(0, 0);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const jumpToPage = async (pageNumber) => {
    //setActivePage(pageNumber);
    try {
      setActivePage(pageNumber);
      window.scrollTo(0, 0);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="dashboard-base">
        <h2>DASHBOARD</h2>

        <div className="dashboard-topSection">
          <div className="dashboard-countCards">
            <Icon image="delete.svg" size={20} />
            Cancelled Order: {orderCount.cancelledOrders}
          </div>

          <div className="dashboard-countCards">
            <Icon image="delivered.svg" size={20} />
            Delivered Order: {orderCount.deliveredOrders}
          </div>

          <div className="dashboard-countCards">
            <Icon image="pending.svg" size={20} />
            Pending Order: {orderCount.pendingOrders}
          </div>
        </div>

        <div className="dashboard-table">
          <h4>To be shipped</h4>
          <Pagination
            total={totalPages}
            currentCount={activePage}
            gotoNext={() => gotoNext()}
            gotoPrevious={gotoPrevious}
            jumpToPage={(value) => jumpToPage(value)}
            loading={loading}
          >
            <Table
              keys={["name", "order_type", "Date", "totalAmount", "status"]}
              data={paginatedTable}
              actionKey={"_id"}
              viewAction={(value) => viewOrder(value)}
              editAction={(value) => editOrder(value)}
            />
          </Pagination>
        </div>
      </div>

      <Modal
        show={showImageModal}
        close={() => {
          setShowImageModal(false);
          setImage("");
        }}
      >
        <div
          style={{
            background: primaryColorLight,
            overflowY: "auto",
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            width: 392,
          }}
        >
          <Image src={image} paddingBottom={"167%"} />
        </div>
      </Modal>

      <Modal show={showViewModal} close={() => setShowViewModal(false)}>
        <ViewOrder data={viewModalData} />
      </Modal>

      <Modal show={showUpdateModal} close={() => setShowUpdateModal(false)}>
        <UpdateOrder
          data={updateModalData}
          getOrders={() => getPaidOrderData()}
          closeModal={() => setShowUpdateModal(false)}
          totalDataStore={paidOrders}
          updateTotalOrderStore={setPaidOrders}
        />
      </Modal>
    </>
  );
}
