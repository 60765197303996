import { ACCESS_TOKEN } from "./Constants";
import { getCookie } from "./Cookies";

export const IMAGE_API_URL = process.env.REACT_APP_IMAGE_API_KEY;
const LOCAL_API_URL = "http://localhost:5000";
const API_URL = process.env.REACT_APP_API_KEY;
// const API_URL = LOCAL_API_URL;

export const get = (url) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const accessToken = getCookie(ACCESS_TOKEN);
  if (accessToken) {
    headers.Authorization = "Bearer " + accessToken;
  }

  return fetch(`${API_URL}/${url}`, {
    method: "get",
    headers,
  });
};

export const post = (url, data = [], apiUrl = API_URL) => {
  console.log(apiUrl);
  try {
    const headers = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    };

    const accessToken = getCookie(ACCESS_TOKEN);
    if (accessToken) {
      headers.Authorization = "Bearer " + accessToken;
    }

    return fetch(`${apiUrl}/${url}`, {
      method: "post",
      body: JSON.stringify(data),
      headers,
    });
  } catch (err) {
    console.log(err);
  }
};

export const patch = (url, data = [], apiURL = API_URL) => {
  const headers = {
    "Content-Type": "application/json",
    mode: " no-cors",
    // "Cache-Control": "no-cache",
  };
  const accessToken = getCookie(ACCESS_TOKEN);
  if (accessToken) {
    headers.Authorization = "Bearer " + accessToken;
  }
  return fetch(`${apiURL}/${url}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers,
  });
};

export const put = (url, data = {}, apiURL = API_URL) => {
  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  };
  const accessToken = getCookie(ACCESS_TOKEN);
  if (accessToken) {
    headers.Authorization = "Bearer " + accessToken;
  }
  return fetch(`${apiURL}/${url}`, {
    method: "put",
    body: JSON.stringify(data),
    headers,
  });
};
