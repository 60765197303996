import React, { useContext, useEffect, useState } from 'react';
import './Css/Coupons.css';
import { GlobalContext } from '../../ContextProvider/ContextProvider';
import { get, put } from '../../utils/apiRequest';
import Table from '../Table/Table';
import Icon from '../../general/Icon';
import { COUPON_STATUS, PERCENT } from '../../utils/Constants';
import Modal from '../../general/Modal';
import ViewCoupon from './ViewCoupon';
import AddCoupon from './AddCoupon';

export default function Coupons() {
  const couponData = useContext(GlobalContext).couponData;
  const updateCouponsData = useContext(GlobalContext).setCouponData;

  const [showAddModal, setShowAddModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const [showViewModal, setShowViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({});

  useEffect(() => {
    getCoupons();
  }, []);

  async function getCoupons() {
    const response = await get('coupon');
    const responseJson = await response.json();
    if (responseJson.status === 'success') updateCouponsData(responseJson.data);
  }
  const tableData = [];
  if (couponData.length) {
    couponData.forEach((i) =>
      tableData.push({
        _id: i._id,
        code: i.code,
        status: i.status,
        type: i.type,
        value: i.type === PERCENT ? `${i.value}%` : i.value || 0,
        used: i.used,
        created_at: i.createdAt,
        expires_on: new Date(i.expiresOn).toLocaleDateString('en-IN')
      })
    );
  }

  async function viewCoupon(couponId) {
    setShowViewModal(true);

    const selectedCoupon = couponData.find((i) => i._id === couponId);
    setViewModalData(selectedCoupon);
  }

  async function editCoupon(couponId) {
    setShowEditModal(true);

    const selectedCoupon = couponData.find((i) => i._id === couponId);
    setEditModalData(selectedCoupon);
  }

  async function deactivateCoupon(couponId) {
    const selectedCoupon = couponData.find((i) => i._id === couponId);

    const payload = {
      ...selectedCoupon
    };

    payload.status = COUPON_STATUS.inActive;

    const response = await put(`coupon/${couponId}`, payload);

    const responseJson = await response.json();

    if (responseJson.status === 'success') updateCouponsData(payload);
  }

  return (
    <>
      <div className='coupons-base'>
        <h2 className='main-header'>Coupons</h2>

        <div
          className='coupon-topSection'
          onClick={() => setShowAddModal(true)}
        >
          <Icon image={'addCoupon.svg'} size={80} />
          <h4>Add Coupon</h4>
        </div>
        <Table
          keys={['code', 'status', 'type', 'value', 'expires_on']}
          data={tableData}
          actionKey={'_id'}
          // onClick={(value) => console.log(value)}
          viewAction={(value) => viewCoupon(value)}
          editAction={(value) => editCoupon(value)}
          deleteAction={(value) => deactivateCoupon(value)}
        />
      </div>
      {showAddModal && (
        <Modal show={showAddModal} close={() => setShowAddModal(false)}>
          <AddCoupon
            mode='add'
            getCoupons={() => getCoupons()}
            closeModal={() => setShowAddModal(false)}
          />
        </Modal>
      )}

      {showEditModal && (
        <Modal show={showEditModal} close={() => setShowEditModal(false)}>
          <AddCoupon
            mode='edit'
            data={editModalData}
            getCoupons={() => getCoupons()}
            closeModal={() => setShowEditModal(false)}
          />
        </Modal>
      )}

      <Modal show={showViewModal} close={() => setShowViewModal(false)}>
        <ViewCoupon data={viewModalData} />
      </Modal>
    </>
  );
}
