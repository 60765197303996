import React from "react";
import throttle from "lodash.throttle";
import "./Css/Pagination.css";

const SCROLL_CHECK_INTERVAL = 200;
const OFFSET_BOTTOM = 800;

export default class Pagination extends React.Component {
  handleScroll = () => {
    return throttle(() => {
      if (
        this.props.total &&
        this.props.currentCount &&
        this.props.currentCount < this.props.total &&
        !this.props.loading
      ) {
        const windowHeight =
          "innerHeight" in window
            ? window.innerHeight
            : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );
        const windowBottom = windowHeight + window.pageYOffset;

        if (
          windowBottom >= docHeight - OFFSET_BOTTOM &&
          window.pageYOffset > 0
        ) {
          this.onLazyLoad();
        }
      }
    }, SCROLL_CHECK_INTERVAL);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledScroll);
  }
  componentDidMount() {
    this.throttledScroll = this.handleScroll();

    window.addEventListener("scroll", this.throttledScroll);
  }
  onLazyLoad = (pageIndex) => {
    const nextPage = parseInt(
      this.props.currentCount / this.props.countPerPage
    );
    if (this.props.onLoad) {
      this.props.onLoad(nextPage);
    }
  };

  render() {
    const total = new Array(this.props.total || 0).fill(0);

    return (
      <div className="paginationBase">
        <div>{this.props.children}</div>

        {this.props.total > 1 ? (
          <ul className="paginationitem">
            <li
              style={{ opacity: this.props.currentCount == 1 && 0.5 }}
              onClick={() =>
                this.props.currentCount !== 1 && this.props.gotoPrevious()
              }
            >
              «
            </li>

            {total.map((i, index) => (
              <li
                className={
                  this.props.currentCount == index + 1 && "pagination-active"
                }
                onClick={() =>
                  this.props.currentCount !== index + 1 &&
                  this.props.jumpToPage(index + 1)
                }
              >
                <span
                  className={
                    this.props.loading &&
                    this.props.currentCount == index + 1 &&
                    "pagination-loading"
                  }
                >
                  {index + 1}
                </span>
              </li>
            ))}

            <li
              style={{
                opacity: this.props.currentCount == this.props.total && 0.5,
              }}
              onClick={() =>
                this.props.currentCount !== this.props.total &&
                this.props.gotoNext()
              }
            >
              »
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  }
}
Pagination.defaultProps = {
  countPerPage: 10,
};
