import Login from "./Components/Login/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  COUPONS_PATH,
  DASHBOARD_PATH,
  LOGIN_PATH,
  ORDERS_PATH,
  PAYMENTS_PATH,
} from "./utils/RouteUrl";
import WithSidebarHeaderWrapper from "./Components/WithSidebarHeaderWrapper";
import Dashboard from "./Components/Dashboard/Dashboard";
import Coupons from "./Components/Coupons/Coupons";
import Orders from "./Components/Orders/Orders";
import ContextProvider from "./ContextProvider/ContextProvider";
import Payments from "./Components/Payments";
// import Pagination from "./Components/Pagination/Pagination";

function App() {
  return (
    <ContextProvider>
      <Routes>
        <Route path={LOGIN_PATH} element={<Login />} />

        <Route path="/" element={<WithSidebarHeaderWrapper />}>
          <Route path={DASHBOARD_PATH} element={<Dashboard />} />
          <Route path={COUPONS_PATH} element={<Coupons />} />
          <Route path={ORDERS_PATH} element={<Orders />} />
          <Route path={PAYMENTS_PATH} element={<Payments />} />
        </Route>
      </Routes>
    </ContextProvider>
    // <Pagination/>
  );
}

export default App;
