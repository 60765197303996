import React from "react";
import HorizontalTable from "../../general/HorizontalTable";
import "./Css/Coupons.css";

export default function ViewCoupon(props) {
  return (
    <div className="viewCoupon-base">
      <h2 className="viewCoupon-header">View Coupon</h2>
      <HorizontalTable keys={Object.keys(props.data)} data={props.data} />{" "}
    </div>
  );
}
