export const primaryColor = "var(--primary-color)";
export const primaryColorLight = "var(--primary-color-light)";

export const errorBorder = "var(--error-color)";
export const errorBackground = "var(--error-background)";

export const inputFontSize = 14;
export const inputBoxRadius = 7;
export const buttonRadius = 7;
export const buttonFont = 16;
