import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../ContextProvider/ContextProvider";
import Button from "../../general/Button";
import { patch } from "../../utils/apiRequest";
import { ORDER_STATUS } from "../../utils/Constants";
import "../Orders/Css/UpdateOrder.css";
import Input from "../../general/Input";

export default function UpdatePayment(props) {
  const [inrPrice, updateInrPrice] = useState(
    props.data && props.data.price && props.data.price.inr
      ? props.data.price.inr
      : ""
  );
  const [usdPrice, updateUsdPrice] = useState(
    props.data && props.data.price && props.data.price.usd
      ? props.data.price.usd
      : ""
  );
  const [status, setStatus] = useState(props.data.status);
  const setShowToast = useContext(GlobalContext).setShowToast;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.data && props.data.price) {
        updateInrPrice(props.data.price.inr)
        updateUsdPrice(props.data.price.usd)
    }
  }, [props.data]);

  const updateStatus = async () => {
    try {
        setLoading(true);
      const paymentId = props.data._id;
      const payload = {
        price: {
          inr: inrPrice,
          usd: usdPrice
        }
      };

      console.log(payload);

      const response = await patch(`payment/${paymentId}`, payload);
      const responseJson = await response.json();
      if (responseJson.status === "success") {
        const data = [...props.totalDataStore];
        console.log(props.totalDataStore);
        const updateIndex = data.findIndex(i => {
          return i._id === responseJson.data._id;
        });
        data[updateIndex] = responseJson.data
        props.updateTotalOrderStore(data);
        props.closeModal()
        setShowToast({
          status: true,
          message: {
            head: "Success",
            content: "Status Updated"
          }
        });
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  console.log(props);
  if (!props.data) {
    return null;
  }

  return (
    <div className="editOrder-base">
      <h2 className="main-header">Update Payment</h2>

      <div className={"login-formGroup"}>
        <label className="label">Change Amount</label>

        <div>
          <div className={"input-wrapper"}>
            <Input
              value={inrPrice}
              onChange={value => updateInrPrice(value)}
              leftChild={<span className={"left-child-input"}>INR</span>}
              border="solid 1px"
            />
          </div>
          <div className={"input-wrapper"}>
            <Input
              value={usdPrice}
              onChange={usd => updateUsdPrice(usd)}
              leftChild={<span className={"left-child-input"}>USD</span>}
              border="solid 1px"
            />
          </div>
        </div>
      </div>

      <Button
        label={"Update Status"}
        borderRadius={5}
        loading={loading}
        onClick={() => updateStatus()}
      />
    </div>
  );
}
