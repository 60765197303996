import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../ContextProvider/ContextProvider';
import Button from '../../general/Button';
import Input from '../../general/Input';
import { post, put } from '../../utils/apiRequest';
import { COUPON_STATUS, PERCENT } from '../../utils/Constants';
import './Css/AddCoupon.css';

export default function AddCoupon(props) {
  const setShowToast = useContext(GlobalContext).setShowToast;
  const couponData = useContext(GlobalContext).couponData;
  const updateCouponsData = useContext(GlobalContext).setCouponData;

  const [input, setInput] = useState({
    code: '',
    value: 0,
    description: '',
    expiresOn: '',
    type: PERCENT
  });

  useEffect(() => {
    if (props.data) {
      setInput({
        code: props.data.code,
        value: props.data.value,
        description: props.data.description,
        expiresOn: props.data.expiresOn.split('T')[0],
        type: props.data.type
      });
    }
  }, [props.data]);

  const addCoupon = async () => {
    const payload = {
      ...input,
      status: COUPON_STATUS.active
    };

    const response = await post('coupon', payload);
    const responseJson = await response.json();

    if (responseJson.status === 'success') {
      //Pushing new coupon data
      const data = [...couponData];
      data.push(responseJson.data);
      await updateCouponsData(data);

      await setShowToast({
        status: true,
        message: {
          head: 'success',
          content: 'New Coupon Added'
        }
      });
      props.closeModal();
    }
  };

  const editCoupon = async () => {
    const couponId = props.data._id;
    const payload = {
      ...input,
      status: COUPON_STATUS.active
    };

    const response = await put(`coupon/${couponId}`, payload);
    const responseJson = await response.json();

    if (responseJson.status === 'success') {
      const data = [...couponData];

      const filterData = data.filter((i) => {
        return i._id !== responseJson.data._id;
      });
      filterData.push(responseJson.data);
      await updateCouponsData(filterData);
      await setShowToast({
        status: true,
        message: {
          head: 'Success',
          content: 'Coupon Updated'
        }
      });
      props.closeModal();
    }
  };

  return (
    <div className='addCoupon-base'>
      <h2 className='addCoupon-header'>
        {props.mode === 'edit' ? 'Edit Coupon' : 'Add Coupon'}
      </h2>
      <div className={'login-formGroup'}>
        <label className='label'>Code</label>
        <Input
          value={input.code}
          onChange={(value) => setInput({ ...input, code: value })}
          borderColor={'#ccc'}
          borderRadius={5}
          background='#fff'
        />
      </div>

      <div className={'login-formGroup'}>
        <label className='label'>Value</label>
        <Input
          value={input.value}
          onChange={(value) => setInput({ ...input, value: value })}
          onlyNumber
          borderColor={'#ccc'}
          borderRadius={5}
          background='#fff'
        />
      </div>

      <div className={'login-formGroup'}>
        <label className='label'>Discount Type</label>
        <div className='row'>
          {/* <div
            className={input.type === FLAT ? "active" : "inactive"}
            onClick={() => setInput({ ...input, type: FLAT })}
          >
            {" "}
            Flat{" "}
          </div> */}
          <div
            className={input.type === PERCENT ? 'active' : 'inactive'}
            onClick={() => setInput({ ...input, type: PERCENT })}
          >
            {' '}
            Percent{' '}
          </div>
        </div>
      </div>

      <div className={'login-formGroup'}>
        <label className='label'>Description</label>
        <Input
          value={input.description}
          onChange={(value) => setInput({ ...input, description: value })}
          borderColor={'#ccc'}
          borderRadius={5}
          background='#fff'
        />
      </div>

      <div className={'login-formGroup'}>
        <label className='label'>Expires On</label>
        <Input
          type='date'
          value={input.expiresOn}
          onChange={(value) => setInput({ ...input, expiresOn: value })}
          borderColor={'#ccc'}
          borderRadius={5}
          background='#fff'
        />
      </div>

      <Button
        label={props.mode === 'edit' ? 'Edit Coupon' : 'Add Coupon'}
        borderRadius={5}
        onClick={() => (props.mode === 'edit' ? editCoupon() : addCoupon())}
      />
    </div>
  );
}
